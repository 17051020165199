import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import axios from "axios";
import { store } from "../../lib/redux/store";

export default async function getUsersFromAzure(userName) {
  try {
    let azureAccessToken = store.getState().auth.ADToken;
    let reqBody = {
      userName: userName,
    };

    const userNameReq = axios.get(
      URL.External.Azure.UserDetails +
        `?$search="userPrincipalName:${userName}"&$orderby=userPrincipalName`,
      {
        data: reqBody,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${azureAccessToken}`,
          ConsistencyLevel: "eventual",
        },
      }
    );

    const userGroupReq = axios.get(
      URL.External.Azure.GroupDetails +
        `?$filter=startswith(displayName,'${userName}')&$expand=members($select=id,displayName)`,
      {
        data: reqBody,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${azureAccessToken}`,
          ConsistencyLevel: "eventual",
        },
      }
    );

    const requests = await Promise.all([userNameReq, userGroupReq]);

    // let result =
    //   userNameReq.data.value?.map((user) => ({
    //     id: user.id,
    //     // name: user.userPrincipalName,
    //     email: user.mail,
    //     label: user.userPrincipalName,
    //     group: false,
    //   })) || [];

    let result = [];

    requests.forEach((req, index) => {
      if (index === 0) {
        let users =
          req.data.value?.map((user) => ({
            id: user.id,
            // name: user.userPrincipalName,
            email: user.mail,
            label: user.userPrincipalName,
            group: false,
          })) || [];
        if (users.length > 0) {
          result.push(...users);
        }
      } else {
        let groups =
          req.data.value?.map((groupData) => ({
            id: groupData.id,
            // name: group.displayName,
            emailId: groupData.mail,
            label: groupData.displayName + " - Group",
            members:
              groupData?.members?.length > 0
                ? groupData.members.map((member) => ({
                    id: member.id,
                    email: member.mail,
                    label: member.userPrincipalName,
                  }))
                : [],
            group: true,
          })) || [];
        if (groups.length > 0) {
          result.push(...groups);
        }
      }
    });

    return result;
  } catch (error) {
    Notification.error(NotificationLabels.ERROR_IN_FETCHING_USERS);
    throw new Error(error);
  }
}
